<template>
  <b-card>
    <h2>
      Grafik pracy
    </h2>

    <FullCalendar :options="calendarOptions" />
  </b-card>
</template>

<script>
import { BCalendar, BCalendarDay, BCard } from 'bootstrap-vue'
import axios from 'axios'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
  name: 'Calendar',
  components: {
    BCalendar,
    BCalendarDay,
    BCard,
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendar: {
        date: new Date(),
        events: [],
      },
      calendarOptions: {
        plugins: [timeGridPlugin],
        initialView: 'timeGridDay',
        events: [
        ],
      },
      employees: [],
      report: {},
    }
  },
  mounted() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    axios.get(`${process.env.VUE_APP_API_URL}Upcoming-Events/Employee/${this.$route.params.id}`, config).then(response => {
      this.calendar.events = response.data
      this.calendarOptions.events = response.data.orders

      this.calendarOptions.events
        .forEach(event => {
          event.title = 'dasd'
          event.start = `${event.date}T${event.start_time}`
          event.end = `${event.date}T${event.end_time}`
        })
    })
  },

}
</script>

<style>
@media (max-width: 575.98px) {
.fc {
    height: 500px;
}
}
</style>
